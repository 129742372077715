import React, { useEffect } from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import { UserProvider, useUserContext } from '../../services/user/userContext';
import Login from '../../components/user/Login';
import VerifyLogin from '../../components/user/VerifyLogin';

const Logout: React.FC<RouteComponentProps> = () => {
  const userContext = useUserContext();
  useEffect(() => {
    userContext.logout().then();
    navigate('/portal/');
  });
  return null;
};

const User: React.FC = () => {
  return (
    <UserProvider>
      <Router style={{ height: '100%' }}>
        <Login path="/user/login" />
        <VerifyLogin path="/user/verify" />
        <Logout path="/user/logout" />
      </Router>
    </UserProvider>
  );
};

export default User;
