import styled from 'styled-components';
import Surface from '../Surface';
import React from 'react';
import { Flex } from 'rebass/styled-components';

const Container = styled(Surface)`
  justify-content: center;
  margin-left: 40px;
  margin-right: 40px;
  @media only screen and (min-width: 768px) {
    max-width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const LoginFormContainer: React.FC = ({ children }) => {
  return (
    <Flex
      marginTop={'0px'}
      flexGrow={1}
      alignItems={'center'}
      flexDirection={'column'}
      justifyContent={'center'}
    >
      <Container>{children}</Container>
    </Flex>
  );
};

export default LoginFormContainer;
