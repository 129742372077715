import React, { useCallback, useRef, useState } from 'react';
import { useUserContext } from '../../services/user/userContext';
import BlogLayout from '../BlogLayout';
import PageHeroHeader from '../PageHeroHeader';
import StyledInput from '../StyledInput';
import SecondaryRaisedButton from '../SecondaryRaisedButton';
import Alert, { AlertProps } from '../Alert';
import LoginFormContainer from './LoginFormContainer';
import styled from 'styled-components';
import LoadingSpinner from '../LoadingSpinner';
import { RequestLoginResult } from '../../services/AuthService';

const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
`;

interface Props {
  onSubmitClicked?: (input: string) => Promise<AlertProps | null>;
}

const Login: React.FC<Props> = ({ ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const userContext = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [alertProps, setAlertProps] = useState<AlertProps | null>(null);
  const performLoginAction = useCallback(async () => {
    setIsLoading(true);
    setAlertProps(null);
    if (
      inputRef.current?.value === undefined ||
      inputRef.current?.value === null
    ) {
      setAlertProps({
        message: 'Email is required.',
        type: 'error',
      });
    } else {
      if (props.onSubmitClicked) {
        setIsLoading(true);
        const alert = await props.onSubmitClicked(inputRef.current.value ?? '');
        if (alert) {
          setAlertProps(alert);
        }
        setIsLoading(false);
      } else {
        const result = await userContext.requestLogin(inputRef.current!.value);
        if (result === RequestLoginResult.REQUESTED) {
          setAlertProps({
            message: 'Check your inbox to access the WTS Video Hub.',
            type: 'success',
          });
        } else if (result === RequestLoginResult.USER_NOT_REGISTERED) {
          setAlertProps({
            message:
              'This is not a registered ticket holder email. Please contact us if you think this is incorrect.',
            type: 'error',
          });
        } else {
          setAlertProps({
            message: inputRef.current?.value
              ? 'Login failed. Please check the email you entered.'
              : 'Email is required.',
            type: 'error',
          });
        }
      }
    }
    setIsLoading(false);
  }, []);
  return (
    <BlogLayout title="WTS Video Hub">
      <PageHeroHeader>WTS Video Hub</PageHeroHeader>
      <LoginFormContainer>
        <div style={{ width: '100%' }}>
          {alertProps && <StyledAlert {...alertProps} />}
        </div>
        <p>
          Please enter your registered ticket holder email to access the video
          hub.
        </p>
        <StyledInput
          autoComplete="email"
          required={true}
          style={{ minWidth: '100%', marginTop: '20px' }}
          placeholder="Email address"
          type="email"
          name="email"
          onKeyUpCapture={(event) => {
            if (event.key === 'Enter') performLoginAction();
          }}
          ref={inputRef}
        />
        <SecondaryRaisedButton
          type="submit"
          disabled={isLoading}
          style={{
            marginTop: '36px',
            marginBottom: '16px',
            width: '100%',
          }}
          onClick={performLoginAction}
        >
          {isLoading ? <LoadingSpinner /> : 'Login'}
        </SecondaryRaisedButton>
      </LoginFormContainer>
    </BlogLayout>
  );
};

export default Login;
