import styled from 'styled-components';

const StyledInput = styled.input`
  flex-grow: 1;
  height: 44px;
  border: 1px solid #cfcfd3;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 8px;
  max-width: 500px;
`;

export default StyledInput;
