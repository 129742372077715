import styled from 'styled-components';
import React from 'react';
import FAIcon from './FAIcon';
import colors from '../../colors';

const AlertContainer = styled.div<AlertProps>`
  width: 100%;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.type === 'error'
        ? props.theme.colors.errorText
        : props.theme.colors.successText};
  background-color: ${(props) =>
    props.type === 'error'
      ? props.theme.colors.errorBackground
      : props.theme.colors.successBackground};
  color: ${(props) =>
    props.type === 'error'
      ? props.theme.colors.errorText
      : props.theme.colors.successText};
  display: flex;
  padding: 10px;
  align-items: center;
`;

export interface AlertProps {
  readonly type: 'success' | 'error';
  readonly message: string;
}

const Alert: React.FC<AlertProps> = ({ type, message }) => {
  const iconColor = type === 'error' ? colors.errorText : colors.successText;
  const icon = type === 'error' ? 'exclamation-triangle' : 'check-circle';
  return (
    <AlertContainer type={type} message={message}>
      <FAIcon name={icon} color={`${iconColor}`} />
      <span style={{ marginLeft: '10px' }}>{message}</span>
    </AlertContainer>
  );
};

export default Alert;
