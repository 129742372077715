import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useUserContext } from '../../services/user/userContext';
import { navigate } from 'gatsby';
import PageHeroHeader from '../PageHeroHeader';
import BlogLayout from '../BlogLayout';
import LoadingSpinner from '../LoadingSpinner';
import { Flex } from 'rebass/styled-components';
import colors from '../../../colors';
import Login from './Login';
import { AlertProps } from '../Alert';

const LoadingScreen: React.FC = () => (
  <Flex
    marginTop={'0px'}
    flexGrow={1}
    alignItems={'center'}
    flexDirection={'column'}
    justifyContent={'center'}
  >
    <LoadingSpinner color={colors.textColor} />
  </Flex>
);

const VerifyLogin: React.FC<RouteComponentProps> = ({ ...props }) => {
  const [shouldAskForEmail, setShouldAskForEmail] = useState(false);
  const userContext = useUserContext();
  const currentLocation = useMemo(() => props?.location?.href ?? '', [props]);
  useEffect(() => {
    if (userContext.canLogin()) {
      userContext.completeLogin(currentLocation).then((result) => {
        if (result) {
          navigate('/portal');
        } else {
          props.navigate?.('/user/login');
        }
      });
    } else {
      setShouldAskForEmail(true);
    }
  }, [currentLocation]);

  const onEmailSubmitted = useCallback(
    async (input: string): Promise<AlertProps | null> => {
      const result = await userContext.completeLogin(currentLocation, input);
      if (result) {
        navigate('/portal');
      } else {
        return {
          message: 'Check the email you entered and try again',
          type: 'error',
        };
      }
      return null;
    },
    [currentLocation],
  );
  if (shouldAskForEmail) {
    return <Login onSubmitClicked={onEmailSubmitted} />;
  } else {
    return (
      <BlogLayout title="WTS Video Hub">
        <PageHeroHeader>WTS Video Hub</PageHeroHeader>
        <LoadingScreen />
      </BlogLayout>
    );
  }
};

export default VerifyLogin;
